import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql, Link, useStaticQuery } from "gatsby";
import "./styles/publicProgram.scss";

function OnlineCoursesPanel({ type }) {
  const courses = useStaticQuery(graphql`
    query OnlineCourses {
      upcoming: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/(online-courses)/" }
          frontmatter: { tags: { eq: "upcoming" } }
        }
      ) {
        nodes {
          frontmatter {
            headline
            subheadline
            subtitle
            metadata {
              LongDescription
            }
          }
          slug
        }
      }
      current: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/(online-courses)/" }
          frontmatter: { tags: { eq: "current" } }
        }
      ) {
        nodes {
          frontmatter {
            headline
            subheadline
            subtitle
            metadata {
              LongDescription
            }
          }
          slug
        }
      }
      selected: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/(public-programs)/" }
          frontmatter: { tags: { eq: "selected" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            thumbnail
            date
            metadata {
              Description
            }
          }
          slug
        }
      }
    }
  `);

  function UpcomingPanel() {
    return (
      <Row className="upcoming-courses">
        <h1>Upcoming Open Enrollment Courses</h1>
        {courses.upcoming.nodes.map((node, index) => (
          <Col key={index} xs={12}>
            <h2 className="mb-1">
              {node.frontmatter.headline} {node.frontmatter.subheadline}
            </h2>
            <h4 className="mb-2">{node.frontmatter.subtitle}</h4>
            {node.frontmatter.metadata.LongDescription.split("\n").map(
              function (item, key) {
                return <p key={key}>{item}</p>;
              },
            )}
            <Link to={`/${node.slug}`} className="btn btn-secondary">
              Learn more »
            </Link>
            <hr />
          </Col>
        ))}
      </Row>
    );
  }

  function CurrentPanel() {
    if (courses.current.nodes.length === 0) {
      return (
        <Row className="upcoming-courses">
          <h1>Current Open Enrollment Courses</h1>
          <Col xs={12}>
            <h2 className="mb-1">Fall courses coming soon</h2>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="upcoming-courses">
          <h1>Current Open Enrollment Courses</h1>

          {courses.current.nodes.map((node, index) => (
            <Col key={index} xs={12}>
              <h2 className="mb-1">
                {node.frontmatter.headline} {node.frontmatter.subheadline}
              </h2>
              <h4 className="mb-2">{node.frontmatter.subtitle}</h4>
              {node.frontmatter.metadata.LongDescription.split("\n").map(
                function (item, key) {
                  return <p key={key}>{item}</p>;
                },
              )}
              <Link to={`/${node.slug}`} className="btn btn-secondary">
                Learn more »
              </Link>
              <hr />
            </Col>
          ))}
        </Row>
      );
    }
  }

  function SelectedPanel() {
    return (
      <Row className="selected-courses">
        <h1>Selected Recent Previous Programs</h1>
        {courses.selected.nodes.map((node, index) => (
          <Col key={index} xs={12}>
            <Row>
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <Link to={`/${node.slug}`}>
                  <img
                    src={node.frontmatter.thumbnail}
                    className="img-fluid"
                    alt={`${node.frontmatter.title} thumbnail`}
                  />
                </Link>
              </Col>
              <Col xs={12} md={6}>
                <h2 className="mb-1">{node.frontmatter.title}</h2>
                <p>{node.frontmatter.metadata.Description}</p>
                <Link to={`/${node.slug}`} className="btn btn-secondary">
                  Learn more »
                </Link>
              </Col>
            </Row>
            <hr />
          </Col>
        ))}
      </Row>
    );
  }

  if (type === "upcoming") {
    return <UpcomingPanel />;
  }
  if (type === "current") {
    return <CurrentPanel />;
  } else {
    return <SelectedPanel />;
  }
}

export default OnlineCoursesPanel;
