import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import BasicLearnMorePanel from "../../../components/basicLearnMorePanel";
import Celebrating30Years from "../../../components/celebrating30Years";
import ContactForm from "../../../components/forms/contactForm";
import ContentQuoteRight from "../../../components/contentQuoteRight.js";
import DefaultHeader from "../../../components/default-header";
import Footer from "../../../components/footer";
import GdprPanel from "../../../components/gdpr";
import Navigation from "../../../components/navigation";
import OnlineCoursesPanel from "../../../components/onlineCoursesPanel";
import Seo from "../../../components/seo";
import UpcomingPublicEvent from "../../../components/upcomingPublicEvent";
import NewsletterSignup from "../../../components/newsletterSignUp.js";

const Page = ({ data }) => {
  const shortcodes = {
    ContentQuoteRight,
    UpcomingPublicEvent,
    OnlineCoursesPanel,
    Row,
    Col,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body">
          <Container fluid="lg">
            <Row>
              <Col lg={9} md={8} className="pe-md-5 mb-md-5 mb-3">
                <MDXProvider components={shortcodes}>
                  <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>
              </Col>
              <Col lg={3} md={4}>
                <Row className="mt-md-5 p-3 white-bg">
                  <Col>
                    <img
                      src="https://web-cdn.meridianuniversity.edu/logos/Meridian_CFTL.png"
                      alt="Meridian University Center for Transformative Learning Logo"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
                <BasicLearnMorePanel menuType="basic" />
              </Col>
            </Row>
            <Celebrating30Years />
          </Container>
        </div>
        <ContactForm
          submitCampaignID="7013i000000y1rFAAQ"
          submitType="contact"
        />
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ProgramsAndEventsPageQuery {
    mdx(fileAbsolutePath: { regex: "/public-programs-overview.mdx/" }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;

export default Page;
